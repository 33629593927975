import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/docs/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "getting-started"
    }}>{`Getting started`}</h1>
    <h2 {...{
      "id": "deploying-your-software-with-nanoapi"
    }}>{`Deploying your software with NanoAPI`}</h2>
    <p>{`You can have your first application up and running on NanoAPI `}<strong parentName="p">{`in under 10 minutes.`}</strong>{` Follow the steps below to see how easy it is to get your software configured, built, and deployed.`}</p>
    <h3 {...{
      "id": "what-youll-need"
    }}><strong parentName="h3">{`What you’ll need:`}</strong></h3>
    <p>{`Before you start, please check you have the following:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`GitHub or GitLab Account:`}</strong>
        <ul parentName="li">
          <li parentName="ul">{`A valid GitHub or GitLab account is required for signing up and granting access permissions to NanoAPI.`}</li>
        </ul>
      </li>
      <li parentName="ul"><strong parentName="li">{`Repository to Deploy:`}</strong>
        <ul parentName="li">
          <li parentName="ul">{`A repository on GitHub or GitLab that you want to deploy using NanoAPI.`}</li>
        </ul>
      </li>
      <li parentName="ul"><strong parentName="li">{`Login Credentials:`}</strong>
        <ul parentName="li">
          <li parentName="ul">{`Your GitHub or GitLab username/email and password for authentication during the signup and approval process.`}</li>
        </ul>
      </li>
    </ul>
    <h2 {...{
      "id": "step-1-sign-up-to-nanoapi-2-min"
    }}>{`Step 1: Sign Up to NanoAPI (2 min.)`}</h2>
    <p><strong parentName="p">{`What you’ll need:`}</strong></p>
    <ul>
      <li parentName="ul">{`GitHub or GitLab Account.`}</li>
      <li parentName="ul">{`Login Credentials.`}</li>
    </ul>
    <ol>
      <li parentName="ol"><strong parentName="li">{`Go to `}<a parentName="strong" {...{
            "href": "https://app.nanoapi.io/signup"
          }}>{`app.nanoapi.io/signup`}</a></strong>{` `}</li>
    </ol>
    <p><img alt="Sign Up" src={require("../../public/0-login.png")} /></p>
    <ol>
      <li parentName="ol"><strong parentName="li">{`Choose a Signup Method:`}</strong>
        <ul parentName="li">
          <li parentName="ul">{`You will be presented with options to sign up using different methods. Choose either:`}
            <ul parentName="li">
              <li parentName="ul"><strong parentName="li">{`GitHub Account:`}</strong>{` Click on the "Sign up with GitHub" button.`}</li>
              <li parentName="ul"><strong parentName="li">{`GitLab Account:`}</strong>{` Click on the "Sign up with GitLab" button.`}</li>
            </ul>
          </li>
        </ul>
      </li>
      <li parentName="ol"><strong parentName="li">{`Authenticate with Your Chosen Account:`}</strong>
        <ul parentName="li">
          <li parentName="ul">{`If you selected GitHub:`}
            <ul parentName="li">
              <li parentName="ul">{`You will be redirected to GitHub’s login page. Enter your GitHub credentials (username/email and password) and click "Sign in."`}</li>
            </ul>
          </li>
          <li parentName="ul">{`If you selected GitLab:`}
            <ul parentName="li">
              <li parentName="ul">{`You will be redirected to GitLab’s login page. Enter your GitLab credentials (username/email and password) and click "Sign in."`}</li>
            </ul>
          </li>
        </ul>
      </li>
      <li parentName="ol"><strong parentName="li">{`Grant and Authorize NanoAPI:`}</strong>
        <ul parentName="li">
          <li parentName="ul">{`After logging in, you are asked to authorize NanoAPI to access your GitHub or GitLab account. Click “Grant” to grant acces to your organisations and then review the permissions and click "Authorize" to proceed.`}</li>
        </ul>
      </li>
    </ol>
    <h2 {...{
      "id": "step-2-search-for-a-repository-1-min"
    }}>{`Step 2: Search for a Repository (1 min.)`}</h2>
    <p><strong parentName="p">{`What you’ll need:`}</strong></p>
    <ul>
      <li parentName="ul">{`Repository to Deploy.`}</li>
    </ul>
    <ol>
      <li parentName="ol"><strong parentName="li">{`Redirect to Dashboard:`}</strong>
        <ul parentName="li">
          <li parentName="ul">{`After approving access to your GitHub or GitLab account, you will be redirected to the NanoAPI dashboard.`}</li>
        </ul>
      </li>
      <li parentName="ol"><strong parentName="li">{`Search for a Repository:`}</strong>
        <ul parentName="li">
          <li parentName="ul">{`On the dashboard, locate the search bar.`}</li>
          <li parentName="ul">{`Enter the name of the repository you want to deploy or the owner’s name in the search bar. This will be mostly: “ownername/reponame”`}
            {`  `}<img alt="Fresh Login View" src={require("../../public/1-no-repos.png")} />
          </li>
        </ul>
      </li>
      <li parentName="ol"><strong parentName="li">{`Locate the Repository:`}</strong>
        <ul parentName="li">
          <li parentName="ul">{`Browse through the search results to find the repository you are looking for. Refer to the provided image if needed:`}
            {`  `}<img alt="Repos" src={require("../../public/repos.png")} />
          </li>
        </ul>
      </li>
    </ol>
    <h2 {...{
      "id": "step-3-build-configuration-2-min"
    }}>{`Step 3: Build configuration (2 min.)`}</h2>
    <p><strong parentName="p">{`What you’ll need:`}</strong></p>
    <ul>
      <li parentName="ul">{`Repository to Deploy.`}</li>
    </ul>
    <ol>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Redirect to Build Config Screen:`}</strong></p>
        <ul parentName="li">
          <li parentName="ul">{`Click on the "View builds" button at your repository, you will see an option labeled "Create/View build configs." Click on this option.`}</li>
        </ul>
        <p parentName="li"><img alt="Build Config" src={require("../../public/2-build-config.png")} /></p>
      </li>
    </ol>
    <ol>
      <li parentName="ol"><strong parentName="li">{`Build Config Screen:`}</strong>
        <ul parentName="li">
          <li parentName="ul">{`You will be taken to the build config screen.`}</li>
        </ul>
      </li>
      <li parentName="ol"><strong parentName="li">{`Create New Build Config:`}</strong>
        <ul parentName="li">
          <li parentName="ul">{`On the build config screen, locate the option to create a new build config.`}</li>
          <li parentName="ul">{`Click on the "Create New" button to start configuring your new service.`}</li>
        </ul>
      </li>
      <li parentName="ol"><strong parentName="li">{`Fill in Configuration Details:`}</strong>
        <ul parentName="li">
          <li parentName="ul">{`Provide the necessary details for your build configuration. This may include:`}
            <ul parentName="li">
              <li parentName="ul"><strong parentName="li">{`Build Name:`}</strong>{` A unique name for your build configuration.`}</li>
              <li parentName="ul"><strong parentName="li">{`Environment Variables:`}</strong>{` Any environment variables needed for your service.`}</li>
              <li parentName="ul"><strong parentName="li">{`Build Commands:`}</strong>{` Commands to build your application.`}</li>
              <li parentName="ul"><strong parentName="li">{`Deployment Settings:`}</strong>{` Settings specific to your deployment environment.`}</li>
            </ul>
          </li>
          <li parentName="ul">{`Make sure to fill in all required fields accurately.`}
            {`  `}<img alt="New Build Config" src={require("../../public/3-new-bc.png")} />
          </li>
        </ul>
      </li>
      <li parentName="ol"><strong parentName="li">{`Save Configuration:`}</strong>
        <ul parentName="li">
          <li parentName="ul">{`Once you have entered all the necessary details, click on the "Save" button to save your build configuration.`}</li>
        </ul>
      </li>
    </ol>
    <h2 {...{
      "id": "step-4-create-an-api-token-1-min"
    }}>{`Step 4: Create an API Token (1 min.)`}</h2>
    <p><strong parentName="p">{`What you’ll need:`}</strong></p>
    <ul>
      <li parentName="ul">{`Repository to Deploy.`}</li>
    </ul>
    <ol>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Navigate to the API Tokens Page`}</strong>{`:`}</p>
        <ul parentName="li">
          <li parentName="ul">{`Go to the API Tokens page.`}</li>
        </ul>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Create a New Token`}</strong>{`:`}</p>
        <ul parentName="li">
          <li parentName="ul">{`Click on the "Create a new token" button.`}</li>
        </ul>
        <p parentName="li"><img alt="Token" src={require("../../public/4-token.png")} /></p>
        <ul parentName="li">
          <li parentName="ul">{`Enter a name for your token.`}</li>
        </ul>
        <p parentName="li"><img alt="Name Token" src={require("../../public/5-name-token.png")} /></p>
        <ul parentName="li">
          <li parentName="ul">{`The new token will appear in the upper right corner of the screen.`}</li>
          <li parentName="ul">{`Click on the token to copy it to your clipboard.`}
            {`  `}<img alt="Many Tokens" src={require("../../public/6-many-tokens.png")} />
          </li>
        </ul>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Secure Your Token in GitHub or GitLab`}</strong>{`:`}</p>
        <ul parentName="li">
          <li parentName="ul">{`Keep the token safe, as it is used to authenticate your service with Nano API.`}</li>
          <li parentName="ul">{`Note that you can create multiple tokens and revoke them anytime.`}</li>
        </ul>
        <ol parentName="li">
          <li parentName="ol"><strong parentName="li">{`Add the API Token to Your Repository as a Secret Value:`}</strong>
            <ul parentName="li">
              <li parentName="ul">{`Navigate to your repository on GitHub or GitLab.`}</li>
              <li parentName="ul">{`Go to the "Settings" page.`}</li>
            </ul>
          </li>
          <li parentName="ol"><strong parentName="li">{`Add a New Secret`}</strong>{`:`}
            <ul parentName="li">
              <li parentName="ul">{`Find the "Secrets" or "Environment Variables" tab.`}</li>
              <li parentName="ul">{`Click on "New Secret" or "Add Secret."`}</li>
            </ul>
          </li>
          <li parentName="ol"><strong parentName="li">{`Name and Paste the Secret`}</strong>{`:`}
            <ul parentName="li">
              <li parentName="ul">{`Name your secret `}<inlineCode parentName="li">{`NANO_API_TOKEN`}</inlineCode>{` (or another preferred name).`}</li>
              <li parentName="ul">{`Paste the API token as the value for this secret.`}</li>
            </ul>
          </li>
        </ol>
      </li>
    </ol>
    <h2 {...{
      "id": "step-5-set-up-your-cicd-pipeline-2-min"
    }}>{`Step 5: Set Up Your CI/CD Pipeline (2 min.)`}</h2>
    <p><strong parentName="p">{`What you’ll need:`}</strong></p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`GitHub or GitLab Account.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Repository to Deploy.
`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Create a Workflow File`}</strong>{`:`}</p>
        <ul parentName="li">
          <li parentName="ul">{`For GitHub: In your repository, navigate to `}<inlineCode parentName="li">{`.github/workflows`}</inlineCode>{`.`}</li>
          <li parentName="ul">{`For GitLab: In your repository, navigate to `}<inlineCode parentName="li">{`.gitlab-ci.yml`}</inlineCode>{`.`}</li>
          <li parentName="ul">{`Create a new file named `}<inlineCode parentName="li">{`deploy_using_nano.yml`}</inlineCode>{`.`}</li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Add Workflow Configuration`}</strong>{`:`}</p>
        <ul parentName="li">
          <li parentName="ul">
            <p parentName="li">{`Copy and paste the following content into `}<inlineCode parentName="p">{`deploy_using_nano.yml`}</inlineCode>{`. This file should exist within the `}<inlineCode parentName="p">{`.github/workflows`}</inlineCode>{` or the root directory of your repository.`}</p>
            <p parentName="li"><img alt="Untitled" src={require("../../public/7-create-build.png")} /></p>
          </li>
          <li parentName="ul">
            <p parentName="li">{`Copy this into your new GitHub CI file:`}</p>
            <pre parentName="li"><code parentName="pre" {...{
                "className": "language-yaml"
              }}>{`# .github/workflows/deploy-nanoapi.yml

name: nanoapi_build_and_deploy

on: [push]
jobs:
    ci:
    runs-on: ubuntu-latest
    steps:
        - name: Build and deploy via Nano API
        uses: Nano-API/Deploy@version/0
        with:
            apiKey: \${{ secrets.NANO_API_TOKEN }} # Your Nano API token, stored in Github secrets
            buildConfigId: '<your_build_config_id>'
            commitSha: \${{ github.sha }}
`}</code></pre>
          </li>
        </ul>
      </li>
    </ul>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Note:`}</strong>{` Replace `}<inlineCode parentName="p">{`<your_build_config_id>`}</inlineCode>{` with the ID of the build configuration you created in Step 3.`}</p>
    </blockquote>
    <h2 {...{
      "id": "step-6-push-a-new-commit-1-min"
    }}>{`Step 6: Push a New Commit (1 min.)`}</h2>
    <p><strong parentName="p">{`What you’ll need:`}</strong></p>
    <ul>
      <li parentName="ul">{`GitHub or GitLab Account.`}</li>
      <li parentName="ul">{`Repository to Deploy.`}</li>
    </ul>
    <ol>
      <li parentName="ol"><strong parentName="li">{`Make Changes`}</strong>{`:`}
        <ul parentName="li">
          <li parentName="ul">{`Make any necessary changes to your code or repository.`}</li>
        </ul>
      </li>
      <li parentName="ol"><strong parentName="li">{`Commit and Push`}</strong>{`:`}
        <ul parentName="li">
          <li parentName="ul">{`Commit your changes.`}</li>
          <li parentName="ul">{`Push the commit to your repository.`}</li>
        </ul>
      </li>
      <li parentName="ol"><strong parentName="li">{`Trigger the Workflow`}</strong>{`:`}
        <ul parentName="li">
          <li parentName="ul">{`The push will trigger the CI/CD pipeline.`}</li>
          <li parentName="ul">{`Monitor the build and deploy process in the Actions tab of your repository.`}</li>
        </ul>
      </li>
    </ol>
    <h3 {...{
      "id": "wrap-up"
    }}>{`Wrap Up`}</h3>
    <p>{`You have successfully set up automatic builds and deployments for your service using Nano API in under 10 minutes. With this setup, every time you push a new commit, Nano API will automatically build and deploy your service, facilitating quick iterations and deployments.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      